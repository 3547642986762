.error_page_section {
    margin-top: 60px;
}

.error-details-section {
    margin-top: 40px;
}

@media (max-width: 767px) {
    .error_page_section {
        margin-top: 0;
    }

    .error-details-section {
        margin-top: 0;
    }
}
.spinner {
    display:inline-block;
     height:50px;
     width:50px;
     -webkit-animation: rotation 1s linear 0s infinite;
     -moz-animation: rotation 1s linear 0s infinite;
     -o-animation: rotation 1s linear 0s infinite;
     animation: rotation 1s linear 0s infinite;
     border-left:8px solid rgba(0,174,239,.15);
     border-right:8px solid rgba(0,174,239,.15);
     border-bottom:8px solid rgba(0,174,239,.15);
     border-top:8px solid #BAFD9A;
     border-radius:100%;
     position: relative;
     top: 50%;
     margin: -50px auto auto auto;
  }

  .spinner-wrapper {
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    text-align: center;
    background: white;
    opacity:0.8;
    position:absolute;
    z-index: 200;
    overflow: hidden;
  }

  .spinner-wrapper-text {
   position: relative;
   top: 50%;
   font-weight: 400;
    font-family: 'MonaSans', Helvetica, arial, sans-serif !important;
  }

  @-webkit-keyframes rotation {
     from {-webkit-transform: rotate(0deg);}
     to {-webkit-transform: rotate(359deg);}
  }
  @-moz-keyframes rotation {
     from {-moz-transform: rotate(0deg);}
     to {-moz-transform: rotate(359deg);}
  }
  @-o-keyframes rotation {
     from {-o-transform: rotate(0deg);}
     to {-o-transform: rotate(359deg);}
  }
  @keyframes rotation {
     from {transform: rotate(0deg);}
     to {transform: rotate(359deg);}
  }
.breadcrumb-wrapper {
    width: 100%;
    display: -webkit-box;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 80px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    position: relative;
}

.an-breadcrumb__list {
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    list-style: none;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
}

.an-breadcrumb__item{
    display: inline-block;
    margin: 0;
    color: #444;
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    font-weight: 400;
}

.an-breadcrumb__link{
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    color: var(--upm-green);
    font-weight: 400;
    text-decoration: none;
}

.an-breadcrumb__link:active, .an-breadcrumb__link:hover {
    text-decoration: none;
    color: var(--upm-green);
    font-weight: 400;
}

.divider {
    padding: 0 5px 0 5px;
    color: #888;
    font-weight: 400;
}
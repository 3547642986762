.loader-wrapper {
    padding-top: 100px;
}

.user-management-caption-section {
    justify-content: stretch;
    align-items: center;
    display: flex;
    line-height: 25px;
    padding: 10px 0 20px;
}

.user-management-caption {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    max-width: calc(100% - 72px);
}

.user-management-caption-company-app {
    display:inline-block;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 18px;
    line-height: 30px;
}

.breadcrumb-spacer {
    font-size: 14px;
    display:inline-block;
    margin-left: 5px;
}

.create-user-spacer {
    margin-top: 40px;
}
.footer-container {
	position: relative;
	z-index: 9999 !important;
}

.upm-footer {
	margin-top: 0 !important;
	background-color: #272727;
	color: #bbb
}

.fixed .upm-footer {
	margin-top: 0
}

.upm-footer__row {
	border-bottom: 1px solid #373737;
	padding-left: 12px;
	padding-right: 12px
}

.upm-footer__bottom-wrap {
	width: 100%;
	max-width: 1146px;
	margin: 0 auto;
	height: 100%;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	min-height: 56px;
	padding: 7px 0;
	text-align: center;
}

.upm-footer__bottom-wrap ul {
	list-style: none
}

.upm-footer__bottom-wrap ul li {
	position: relative;
	margin: 0;
	color: #bbb
}

.upm-footer__bottom-wrap ul li a {
	text-decoration: none
}

.upm-footer__bottom-wrap ul p {
	margin-bottom: 5px;
	color: #bbb
}

.upm-footer__bottom-wrap ul a {
	text-decoration: underline
}

.upm-footer__bottom-wrap a {
	color: #bbb;
	text-decoration: none
}

.upm-footer__bottom-list-container {
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	display: inline;
	margin: 14px 0 0
}

.upm-footer__bottom-list-container ul {
	display: -webkit-inline-box;
	display: inline-flex;
	flex-wrap: wrap;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	list-style: none;
	margin: 0
}

.upm-footer__bottom-list-container ul li {
	margin: 0 8px 0 0;
	color: #bbb
}

.upm-footer__bottom-list-container>a {
	width: 100%
}

.fixed-footer {
	position: fixed;
	bottom: 0; 
	left: 0;
	right: 0;
}

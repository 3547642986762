.help-section {
    width: 80%;
    height: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    padding: 20px;
    margin-bottom: 40px;
}

.help-section-support {
    background-image: url('../../images/helpIcon.png');
    background-repeat: no-repeat;
    background-position: right 30px top 20px;
    background-size: 48px 48px;
}

.help-section-documents {
    background-image: url('../../images/fileIcon.png');
    background-repeat: no-repeat;
    background-position: right 30px top 20px;
    background-size: 48px 48px;
}

.help-section-docs-shadowed {
    width: 30%;
    box-shadow: 2px 0 2px -2px rgba(0, 0, 0, .1);
    display: inline-block;
}

.help-section-docs {
    width: 30%;
    display: inline-block;
}

.help-section-sentence {
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    font-weight: 400;
    font-size: 18px;
    padding-left: 5px;
}

.help-section-sentence-small {
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    font-weight: 400;
    font-size: 18px;
    padding-left: 10px;
    margin: 0 0 0 0;
}

.help-section-link {
    padding-left: 10px;
    font-family: 'MonaSans',Helvetica,arial,sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.help-section-link:hover {
    text-decoration: underline;
}

@media (max-width: 1400px) {
    .help-section {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .help-section {
        width: 100%;
        background-size: 24px;
    }

    .help-section h3 {
        padding-right: 40px;
    }

    .help-section-documents >div {
        display: block;
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 15px;
        box-shadow: none;
    }

    .help-section-documents >div:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
    .help-section-email {
        word-wrap: break-word;
    }
}
.icon-success {
    display:inline;
}

.operation-text {
    margin-left: 30px;
    display:inline-block;
    padding-top: 30px;
    font-weight: 400;
    font-size:18px;
    font-family: 'MonaSans', Helvetica, arial, sans-serif !important;
}

.operation-output-wrapper {
    text-align: center;
}
